import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useSwipeable } from 'react-swipeable';
import { useTimeout } from 'react-use';

const Container = styled.article`
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  margin: 0 auto;
  padding: 0;
  border-top: 0.75rem solid black;
  border-bottom: 0.75rem solid black;
  width: 100%;
  overflow: hidden;
  position: relative;
  max-height: 65vh;
`;

const Button = styled.button`
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid white;
  color: white;
  margin: 0;
  margin-left: 1rem;
  padding: 0.25rem 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  z-index: 100;

  &:focus {
    outline: 0;
  }

  &.disable {
    display: none;
  }
`;

const BackButton = styled(Button)`
  z-index: 100;
  left: 0;
`;

const NextButton = styled(Button)`
  z-index: 101;
`;

const Metadata = styled.p`
  cursor: default;
  margin: 0;
  padding: 0.2rem 0.4rem;
  position: absolute;
  font-size: 0.75rem;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0.5rem;
  text-align: center;
  text-shadow: 2px 2px #333;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Progress = styled.p`
  position: absolute;
  right: 0.5rem;
  bottom: -0.1rem;
  text-shadow: 2px 2px #333;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0 0.25rem;
  line-height: 1rem;
  font-size: 0.75rem;
  margin: 0;
`;

const calculateImagePosition = (action, carouselPosition, maxItems = -1) => {
  if (
    carouselPosition + action >= 0 &&
    carouselPosition + action <= maxItems - 1
  ) {
    return carouselPosition + action;
  } else if (action === undefined) {
    return 0;
  }

  return carouselPosition;
};

function enableFullscreen(newState, setFunction) {
  // ensure that scroll position does not hide the text behind the image
  // document.body.style.overflow = newState ? 'hidden' : 'inherit';
  if (newState) {
    document.body.classList.add('fullscreenImage');
  } else {
    document.body.classList.remove('fullscreenImage');
  }
  window.scrollTo(0, 0);

  setFunction(newState);
}

const Gallery = (data) => {
  // needed to reset sizes correctly after an orientation change
  const maxItems = data.images.edges.length;
  const [carouselPosition, setCarouselPosition] = useState(0);
  const [isFullscreen, setFullscreen] = useState(0);
  const [ready, reset] = useTimeout(90000);

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.deltaX > 0) {
        reset();
        setCarouselPosition(
          calculateImagePosition(1, carouselPosition, maxItems)
        );
      } else {
        setCarouselPosition(
          calculateImagePosition(-1, carouselPosition, maxItems)
        );
      }
    },
  });

  if (ready()) {
    // proceed with next image
    const adjustWith = carouselPosition === maxItems - 1 ? undefined : 1;
    reset();
    setCarouselPosition(
      calculateImagePosition(adjustWith, carouselPosition, maxItems)
    );
  }

  const imageTitle =
    data.images.edges[Number(carouselPosition)].node.fields.name;

  const style = isFullscreen
    ? {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        maxHeight: '100%',
        zIndex: 10,
      }
    : {};
  if (isFullscreen && window.innerWidth < window.innerHeight) {
    // reset fullscreen mode when switched to portrait mode
    enableFullscreen(!isFullscreen, setFullscreen);
  }

  return (
    <Container
      {...handlers}
      style={style}
      onClick={(event) => {
        if (
          event.target &&
          event.target.nodeName.toLowerCase() === 'img' &&
          window.innerWidth > window.innerHeight
        ) {
          // only trigger at <img> clicks & landscape mode
          enableFullscreen(!isFullscreen, setFullscreen);
        }
      }}
    >
      <GatsbyImage
        image={getImage(
          data.images.edges[Number(carouselPosition)].node.childImageSharp
        )}
        layout="constrained"
        style={{ maxHeight: '100%', maxWidth: '1700px' }}
        // TODO: animate https://css-tricks.com/almanac/properties/a/animation/
        alt={imageTitle}
      />
      <BackButton
        onClick={() => {
          reset();
          setCarouselPosition(
            calculateImagePosition(-1, carouselPosition, maxItems)
          );
        }}
        className={carouselPosition <= 0 ? 'disable' : null}
      >
        &lt;
      </BackButton>
      <NextButton
        onClick={() => {
          reset();
          setCarouselPosition(
            calculateImagePosition(1, carouselPosition, maxItems)
          );
        }}
        className={carouselPosition >= maxItems - 1 ? 'disable' : null}
        style={{ right: 0, marginRight: `1rem`, marginLeft: 0 }}
      >
        &gt;
      </NextButton>
      {imageTitle !== '-' && <Metadata>{imageTitle}</Metadata>}
      <Progress>
        {carouselPosition + 1}/{maxItems}
      </Progress>
    </Container>
  );
};

const Imagery = () => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(
          filter: { sourceInstanceName: { eq: "renders" } }
          sort: { fields: [name], order: [ASC] }
        ) {
          edges {
            node {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  transformOptions: { cropFocus: CENTER }
                  breakpoints: [400, 640, 750, 1080, 1366, 1920, 2560]
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
              fields {
                name
              }
            }
          }
        }
      }
    `}
    render={(data) => <Gallery {...data} />}
  />
);

export default Imagery;
