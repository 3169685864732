import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Footer from '../components/footer';
import Header from '../components/header';
import Content from '../components/content';
import Imagery from '../components/imagery';

const IndexPage = () => (
  <Layout>
    <SEO
      context={{ title: 'Elmer Leeuwaarden' }}
      keywords={[`elmer`, `leewuaarden`, `3D`, `artist`, `site`]}
    />
    <Header />
    <Imagery />
    <Content />
    <Footer />
  </Layout>
);

export default IndexPage;
